export type Action = 'update' | 'showDetails' | 'select' | 'delete' | 'download' | 'preview' | 'remove'

type RenderButtonPreset = {
  text?: string
  icon?: string
}

const PRESETS = (t: (key: string) => string): Record<Action, RenderButtonPreset> => {
  return {
    update: { text: t('button.update') },
    showDetails: { text: t('button.showDetails') },
    select: { text: t('button.select') },
    delete: { text: t('button.delete') },
    download: { text: t('button.download') },
    preview: { text: t('button.preview') },
    remove: { text: t('button.remove') },
  }
}

export default () => {
  const { $i18n } = useNuxtApp()
  return PRESETS($i18n.t)
}
