import type { PrismaKeys, PrismaOrderBy, PrismaWhere } from '~/types'

export type PaginationProps = {
  skip?: number
  take?: number
}

export type PrismaQueryParameters<K extends PrismaKeys> = {
  where: PrismaWhere<K>
  orderBy?: PrismaOrderBy<K>[]
} & PaginationProps

/*
 * Correctly combine, format and type the results from `where` and `serverSidePagination` to pass to `useQuery`
 *
 * @param modelKey: The key of Prisma Model
 * @param query: The serverSidePagination and where values in an object
 */
export default <K extends PrismaKeys> (modelKey: K, query: {
  pagination?: MaybeRef<PaginationProps>
  where?: MaybeRef<PrismaWhere<K>>
  orderBy?: MaybeRef<PrismaOrderBy<K>[]>
}) => {
  return computed(() => {
    const where = unref(query.where)
    const pagination = unref(query.pagination)
    const orderBy = unref(query.orderBy)
    return {
      where,
      orderBy,
      ...pagination,
    }
  })
}
