import type { PaginationProps } from 'naive-ui'
import { defu } from 'defu'
import { diff } from 'deep-object-diff'

export default (initialPaginationProps?: Pick<PaginationProps, 'pageCount' | 'pageSize'>) => {
  const paginationSettings = reactive(
    defu(
      initialPaginationProps,
      {
        pageSize: 10,
        size: 'large',
        showQuickJumper: false,
        showSizePicker: false,
        pageSizes: [1, 5, 10, 20, 50, 100],
        page: 1,
        itemCount: Number.NaN,
      } satisfies PaginationProps,
    ),
  )

  const serverSidePagination = computed(() => ({
    take: paginationSettings.pageSize,
    skip: (paginationSettings.page - 1) * paginationSettings.pageSize,
  }))

  const watchForPageCount = (itemCount: Ref<number>) => watch(() => [paginationSettings.pageSize, itemCount.value], (newValue) => {
    const [pageSize, itemCount] = newValue
    paginationSettings.pageCount = Math.ceil(itemCount / pageSize)
    paginationSettings.itemCount = itemCount
  }, { immediate: true })

  const watchForReset = (source: Ref<any>) => watch(source, (newSource, oldSource) => {
    if (Object.keys(diff(newSource, oldSource)).length) {
      paginationSettings.page = 1
    }
  })

  const serverSidePaginationTableProps = {
    'isServerSidePaginated': true,
    'controlledPaginationSettings': paginationSettings,
    'onUpdate:page': (page: number) => {
      paginationSettings.page = page
    },
    'onUpdate:page-size': (pageSize: number) => {
      paginationSettings.pageSize = pageSize
    },
  } as const
  return { serverSidePaginationTableProps, serverSidePagination, watchForReset, watchForPageCount }
}
