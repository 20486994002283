<script setup lang="ts" generic="S extends boolean, T extends Record<string, any>">
import type { DataTableColumns, DataTableCreateSummary, DataTableRowKey, DataTableSortState, PaginationProps } from 'naive-ui'
import type { RowData } from 'naive-ui/es/data-table/src/interface'
import type { Action } from '~/composables/useRenderButtonPresets'
import type { ButtonSize } from '~/composables/useRenderButton'

export type TableConfig<ColumnData> = {
  columns: Array<{ isHidden?: boolean } & DataTableColumns<ColumnData>[number]>
  actionButtons?: ((row: ColumnData) => Action[])
  selectionColumn?: DataTableColumns<ColumnData> | false
  rowProps?: (row: ColumnData) => ({ style: string, onClick: (() => any) | ((e: Event) => any) })
  rowClassName?: (row: ColumnData) => string
  rowKey?: (row: ColumnData) => DataTableRowKey
}
type TableSize = 'small' | 'medium' | 'large'

const props = withDefaults(defineProps<{
  data: S extends true ? undefined : T[]
  tableConfig: TableConfig<T>
  isLoading: boolean
  striped?: boolean
  scrollX?: number
  pageSize?: number
  checkedRowKeys?: DataTableRowKey[]
  size?: TableSize
  isPaginated?: boolean
  initialPaginationSettings?: PaginationProps
  controlledPaginationSettings?: PaginationProps
  isServerSidePaginated?: boolean
  showSummary?: boolean
}>(), {
  striped: false,
  scrollX: 1080,
  pagesize: 10,
  size: 'medium',
  isPaginated: true,
  initialPaginationSettings: () => ({} satisfies PaginationProps),
  controlledPaginationSettings: undefined,
  isServerSidePaginated: false,
  showSummary: false,
})
const emit = defineEmits<{
  (e: Action, entity: T): void
  (e: 'update:checked-row-keys', keys: DataTableRowKey[]): void
  (e: 'update:page', page: number): void
  (e: 'update:page-size', page: number): void
  (e: 'update:sorter', sorter: DataTableSortState): void
}>()

const { $i18n } = useNuxtApp()

const tableSizeToButtonSize: Record<TableSize, ButtonSize> = {
  small: 'small',
  medium: 'small',
  large: 'medium',
}
const actionButtonColumn: DataTableColumns<T> = []
if (props.tableConfig.actionButtons) {
  const renderButtonPresets = useRenderButtonPresets()

  const actionButtonEmit = (e: Event, action: Action, row: T) => {
    e.stopImmediatePropagation()
    emit(action, row)
  }

  actionButtonColumn.push({
    title: '',
    key: 'actions',
    align: 'right',
    width: '225px',
    render: (row: T) => {
      const actionButtonMaker = props.tableConfig.actionButtons
      if (!actionButtonMaker) {
        return
      }

      const buttons = actionButtonMaker(row).map((action) => {
        return useRenderButton({
          ...renderButtonPresets[action],
          size: tableSizeToButtonSize[props.size],
        }, (e: Event) => actionButtonEmit(e, action, row))
      })
      return h('div', { class: 'flex space-x-2' }, buttons)
    },
  })
}

const filteredColumns = computed(() => props.tableConfig.columns.filter(({ isHidden }) => !isHidden))
const assembledColumns: DataTableColumns<T> = [
  ...props.tableConfig.selectionColumn || [],
  ...filteredColumns.value,
  ...actionButtonColumn,
]

const paginationProps = props.controlledPaginationSettings
  ? props.controlledPaginationSettings
  : useTablePagination(props.initialPaginationSettings).serverSidePaginationTableProps as PaginationProps

const summary: DataTableCreateSummary = (data) => {
  const columnNames = assembledColumns.map((c) => {
    // @ts-expect-error `.key` exists, for some reason the naive-ui datatype does not expose it
    return c.key as string
  })

  // Due to an open bug we need to make sure that the summary column exists for every column-key, even if we only want one summary column that spans all columns (as we do here). Issue: https://github.com/tusen-ai/naive-ui/issues/4684
  const summaryColumns = Object.fromEntries(columnNames.map((c) => {
    let summaryText = ''
    if (props.isServerSidePaginated) {
      const totalPageCount = props.controlledPaginationSettings?.pageCount
      const itemCount = props.controlledPaginationSettings?.itemCount

      summaryText = `
      ${
        itemCount
        ? $i18n.t('table.summary.entries.total', { n: itemCount })
        : $i18n.t('table.summary.entries.total.unkown')
      }
      ${
      totalPageCount
        ? $i18n.t('table.summary.pages', { n: totalPageCount })
        : $i18n.t('table.summary.pages.unkown')
      }
      ${$i18n.t('table.summary.entries', { n: data.length })}`
    } else {
      summaryText = `${$i18n.t('table.summary.entries.total', { n: props.data?.length })} ${$i18n.t('table.summary.entries', { n: data.length })}`
    }

    return [c, {
      value: h('div', { class: 'table-summary text-gray-600 italic' }, summaryText),
      colSpan: columnNames.length,
    }]
  }))

  return summaryColumns
}

const rowProps = (row: T, index: number) => {
  if (!props.tableConfig.rowProps || index === -1) {
    return { }
  }
  return props.tableConfig.rowProps(row)
}
</script>

<template>
  <ClientOnly>
    <n-data-table
      :remote="isServerSidePaginated"
      :pagination="paginationProps"
      :columns="assembledColumns"
      :data="data as RowData[]"
      :loading="isLoading"
      :row-props="rowProps"
      :row-class-name="tableConfig.rowClassName"
      :row-key="tableConfig.rowKey"
      :striped="striped"
      :scroll-x="scrollX"
      :size="size"
      max-height="60vh"
      class="overflow-auto"
      :checked-row-keys="checkedRowKeys"
      summary-placement="top"
      :summary="showSummary ? summary : undefined"
      @update:checked-row-keys="keys => emit('update:checked-row-keys', keys)"
      @update:page-size="size => emit('update:page-size', size)"
      @update:page="page => emit('update:page', page)"
      @update:sorter="sorter => emit('update:sorter', sorter)"
    />

    <template #fallback>
      <TheLoader />
    </template>
  </ClientOnly>
</template>

<style>
.n-data-table .n-data-table-wrapper {
  @apply bg-white;
}

.n-data-table-tr--summary .n-data-table-td--summary {
  @apply !bg-white py-2 cursor-default;
}

.n-data-table-expand-placeholder {
  display: none !important;
}
</style>
