import { NButton } from 'naive-ui'

export type ButtonSize = 'tiny' | 'small' | 'medium' | 'large'
type ButtonOptions = {
  text?: string
  icon?: string | null
  size?: ButtonSize
  href?: string
  secondary?: boolean
}

export default ({ text, icon, size, href, secondary }: ButtonOptions, onClick?: Function) => {
  const attributes = {
    class: 'grow',
    size,
    secondary,
    onClick: (e: Event) => onClick && onClick(e),
  } as const
  const content = () => {
    const textComponent = href ? useRouterLink(text ?? href, href, { external: true })() : text
    if (icon) {
      return [useRenderIcon({ name: icon, class: text ? 'mr-1' : '' }), textComponent]
    }

    return textComponent
  }

  return h(NButton, attributes, content)
}
